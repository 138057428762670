// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 全量更新IP的接入点分组 PUT /api/ip/allTransitGroup */
export async function ipAllTransitGroupPut(
  body: API.UpdateTransitGroupRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ip/allTransitGroup', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量自动续费设置。该接口返回的预估费用是团队全部自动续费ip加起来的费用 PUT /api/ip/autoRenew */
export async function ipAutoRenewPut(
  params: {
    // query
    /** autoRenew */
    autoRenew: boolean;
    /** ipIds */
    ipIds: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultbigdecimal>('/api/ip/autoRenew', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 批量删除IP DELETE /api/ip/batchDelete */
export async function ipBatchDeleteDelete(
  params: {
    // query
    /** ipIds */
    ipIds: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ip/batchDelete', {
    method: 'DELETE',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 批量删除IP V2 DELETE /api/ip/batchDeleteV2 */
export async function ipBatchDeleteV2Delete(
  params: {
    // query
    /** ipIds */
    ipIds: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultTaskDto>('/api/ip/batchDeleteV2', {
    method: 'DELETE',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 绑定账户 PUT /api/ip/bindShop */
export async function ipBindShopPut(body: API.IpBindShopParamVo, options?: { [key: string]: any }) {
  return request<API.WebResult>('/api/ip/bindShop', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 绑定账户V2 PUT /api/ip/bindShopV2 */
export async function ipBindShopV2Put(
  body: API.IpBindShopParamVoV2,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ip/bindShopV2', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 购买新IP POST /api/ip/buyIps */
export async function ipBuyIpsPost(
  body: API.CreateBuyIpOrderRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultCreateOrderResponse>('/api/ip/buyIps', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 购买用户自有云账户IP POST /api/ip/buyOperatingCloudIps */
export async function ipBuyOperatingCloudIpsPost(
  body: API.BuyOperatingCloudIpOrderRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultCreateOrderResponse>('/api/ip/buyOperatingCloudIps', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 按类型（或视图）查询 GET /api/ip/byCategory/${param0} */
export async function ipByCategoryByCategoryGet(
  params: {
    // query
    /** unbound */
    unbound?: boolean;
    /** source */
    source?: string;
    /** 视图ID(category=custom时指定) */
    viewId?: number;
    /** IP */
    query?: string;
    /** 国家代码，用逗号连接 */
    countryCodes?: string;
    /** 按标签id过滤 */
    tagIds?: string;
    /** 标签的逻辑条件，支持OR|AND */
    tagLc?: 'AND' | 'NOT' | 'OR';
    /** props */
    props?: string;
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
    /** 排序字段 */
    sortFiled?:
      | 'auto_renew'
      | 'create_time'
      | 'ip'
      | 'locationCn'
      | 'locationEn'
      | 'name'
      | 'period_unit'
      | 'status'
      | 'testing_time'
      | 'type'
      | 'valid_end_date';
    /** 顺序 */
    sortOrder?: 'asc' | 'desc';
    // path
    /** 视图分类 */
    category:
      | 'all'
      | 'aptotic'
      | 'custom'
      | 'dynamic'
      | 'expired'
      | 'expring'
      | 'platform'
      | 'unavailable'
      | 'unbound';
  },
  options?: { [key: string]: any },
) {
  const { category: param0, ...queryParams } = params;
  return request<API.WebResultPageResultIpDetailVo>(`/api/ip/byCategory/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 切换 PUT /api/ip/changeShopIp */
export async function ipChangeShopIpPut(
  body: API.IpBindShopChangeRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ip/changeShopIp', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 判断名称是否存在 GET /api/ip/checkOrGenerateIpName */
export async function ipCheckOrGenerateIpNameGet(
  params: {
    // query
    /** name */
    name: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultstring>('/api/ip/checkOrGenerateIpName', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询团队导入IP数量 GET /api/ip/countByTeam */
export async function ipCountByTeamGet(
  params: {
    // query
    /** teamId */
    teamId: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultint>('/api/ip/countByTeam', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取团队所有IP的国家（地区）列表 GET /api/ip/countries */
export async function ipCountriesGet(options?: { [key: string]: any }) {
  return request<API.WebResultListCountryDto>('/api/ip/countries', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取自有云账户IP方案及价格 GET /api/ip/customerIpRegions/${param0} */
export async function ipCustomerIpRegionsByCloudIdGet(
  params: {
    // path
    /** cloudId */
    cloudId: number;
  },
  options?: { [key: string]: any },
) {
  const { cloudId: param0, ...queryParams } = params;
  return request<API.WebResultCustomerIpInfo>(`/api/ip/customerIpRegions/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取所有IP ID列表 GET /api/ip/ids */
export async function ipIdsGet(options?: { [key: string]: any }) {
  return request<API.WebResultListlong>('/api/ip/ids', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 批量导入代理IP（异步任务版） POST /api/ip/importV2 */
export async function ipImportV2Post(
  body: API.CreateProxyIpRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultTaskDto>('/api/ip/importV2', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询可供账户绑定的ip列表 GET /api/ip/ipForShopBind/${param0} */
export async function ipIpForShopBindByShopIdGet(
  params: {
    // query
    /** 访问次数按多少个月统计 */
    month: number;
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultListIpForShopBindVo>(`/api/ip/ipForShopBind/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 修改名称前后缀以及打标签 PUT /api/ip/nameAndTag */
export async function ipNameAndTagPut(
  body: API.UpdateIpNameAndTagRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ip/nameAndTag', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询IP列表 GET /api/ip/page */
export async function ipPageGet(
  params: {
    // query
    /** teamId */
    teamId: number;
    /** unbindFlag */
    unbindFlag?: boolean;
    /** ip */
    ip?: string;
    /** pageNum */
    pageNum: number;
    /** pageSize */
    pageSize: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultIpDetailVo>('/api/ip/page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 解析IP的xlsx POST /api/ip/parseIpExcel */
export async function ipParseIpExcelPost(
  body: {
    /** file */
    file?: string;
  },
  files?: File[],
  options?: { [key: string]: any },
) {
  const formData = new FormData();
  if (files) {
    formData.append('file', files[0] || '');
  }
  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      formData.append(ele, typeof item === 'object' ? JSON.stringify(item) : item);
    }
  });

  return request<API.WebResultListExcelIpImportVo>('/api/ip/parseIpExcel', {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    ...(options || {}),
  });
}

/** 解析IP的txt POST /api/ip/parseIpTxt */
export async function ipParseIpTxtPost(
  body: Record<string, any>,
  options?: { [key: string]: any },
) {
  return request<API.WebResultListExcelIpImportVo>('/api/ip/parseIpTxt', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 探测IP与中转（或门户）之间的连接情况（返回remoteIp） GET /api/ip/probeIp */
export async function ipProbeIpGet(
  params: {
    // query
    /** ipId */
    ipId: number;
    /** 如果不设置时，选择随机中转，设置为0时表示直连（用门户去连接IP） */
    transitId?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultProbeResult>('/api/ip/probeIp', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 探测代理IP的remoteIp GET /api/ip/probeProxy */
export async function ipProbeProxyGet(
  params: {
    // query
    /** ipId */
    ipId: number;
    /** updateDb */
    updateDb?: boolean;
    /** 如果不设置时，选择随机中转，设置为0时表示直连（用门户去连接IP） */
    transitId?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultProxyProbeWithRemoteIp>('/api/ip/probeProxy', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 探测remoteIp GET /api/ip/probeRemoteIp */
export async function ipProbeRemoteIpGet(
  params: {
    // query
    /** ipId */
    ipId: number;
    /** updateDb */
    updateDb?: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultProxyProbeWithRemoteIp>('/api/ip/probeRemoteIp', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 根据IP与中转连接情况更新IP状态 GET /api/ip/refreshIpStatusByIpTransit */
export async function ipRefreshIpStatusByIpTransitGet(
  params: {
    // query
    /** ipId */
    ipId: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ip/refreshIpStatusByIpTransit', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** ip批量续费 POST /api/ip/renewIps */
export async function ipRenewIpsPost(
  body: API.CreateIpRenewOrderRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultCreateOrderResponse>('/api/ip/renewIps', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 分页查询联营IP GET /api/ip/sharing/page */
export async function ipSharingPageGet(
  params: {
    // query
    pageNum?: number;
    pageSize?: number;
    query?: string;
    sortField?:
      | 'auto_renew'
      | 'create_time'
      | 'ip'
      | 'name'
      | 'period_unit'
      | 'status'
      | 'team_id'
      | 'team_name'
      | 'valid_end_date';
    sortOrder?: 'asc' | 'desc';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultSharingIpVo>('/api/ip/sharing/page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 探测代理IP PUT /api/ip/socks/probe */
export async function ipSocksProbePut(
  params: {
    // query
    /** ipVersion */
    ipVersion?: 'Auto' | 'IPv4' | 'IPv6';
    /** 测试的接入点ID，0表示直连 */
    transitId?: number;
    /** 代理类型 */
    proxyType: string;
    /** 代理用户名 */
    username?: string;
    /** 代理密码 */
    password?: string;
    /** 登录密钥 */
    sshKey?: string;
    /** 代理主机 */
    host: string;
    /** 代理端口 */
    port: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultProxyProbeWithRemoteIp>('/api/ip/socks/probe', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 更新代理ip PUT /api/ip/socks/${param0} */
export async function ipSocksByIpIdPut(
  params: {
    // query
    /** transitType */
    transitType?: 'Auto' | 'Direct' | 'Transit';
    /** 代理类型 */
    proxyType: string;
    /** 代理用户名 */
    username?: string;
    /** 代理密码 */
    password?: string;
    /** 登录密钥 */
    sshKey?: string;
    /** 密码是否修改 */
    passwordSet?: boolean;
    /** 代理主机 */
    host: string;
    /** 代理端口 */
    port: number;
    /** 代理的远程IP，可选。如果不设置，会进行probeProxy拿到远程IP */
    ip?: string;
    /** 描述 */
    description?: string;
    /** 当前团队独占 */
    exclusive?: boolean;
    /** status */
    status?: 'Available' | 'Pending' | 'Unavailable';
    /** testingTime */
    testingTime?: number;
    // path
    /** Ip ID */
    ipId: number;
  },
  options?: { [key: string]: any },
) {
  const { ipId: param0, ...queryParams } = params;
  return request<API.WebResultIpSocksDto>(`/api/ip/socks/${param0}`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 添加用户自有代理ip V2 POST /api/ip/socks2 */
export async function ipSocks2Post(body: API.CreateProxyIpVo, options?: { [key: string]: any }) {
  return request<API.WebResultIpSocksDto>('/api/ip/socks2', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** getIpSources GET /api/ip/sources */
export async function ipSourcesGet(
  params: {
    // query
    /** onlyTeam */
    onlyTeam?: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListstring>('/api/ip/sources', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 批量更新IP的接入点分组 PUT /api/ip/transitGroup */
export async function ipTransitGroupPut(
  body: API.UpdateTransitGroupRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ip/transitGroup', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 全量/批量更新IP的接入点分组 PUT /api/ip/transitGroupV2 */
export async function ipTransitGroupV2Put(
  body: API.UpdateTransitGroupRequestV2,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ip/transitGroupV2', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 解绑账户 PUT /api/ip/unbindShop */
export async function ipUnbindShopPut(
  body: API.IpBindShopParamVo,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ip/unbindShop', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 重置IP PUT /api/ip/${param0}/refresh */
export async function ipByIdRefreshPut(
  params: {
    // path
    /** id */
    id: number;
  },
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.WebResultTaskDto>(`/api/ip/${param0}/refresh`, {
    method: 'PUT',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取IP重置信息 GET /api/ip/${param0}/refreshInfo */
export async function ipByIdRefreshInfoGet(
  params: {
    // path
    /** id */
    id: number;
  },
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.WebResultIpRefreshInfo>(`/api/ip/${param0}/refreshInfo`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 尝试重启IP所在主机（5分钟内只能做一次） GET /api/ip/${param0}/restart */
export async function ipByIdRestartGet(
  params: {
    // path
    /** id */
    id: number;
  },
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/ip/${param0}/restart`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取IP的接入点分组 GET /api/ip/${param0}/transitGroups */
export async function ipByIdTransitGroupsGet(
  params: {
    // path
    /** id */
    id: number;
  },
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.WebResultListIpTransitGroupVo>(`/api/ip/${param0}/transitGroups`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** IP详情 GET /api/ip/${param0} */
export async function ipByIpIdGet(
  params: {
    // path
    /** ipId */
    ipId: number;
  },
  options?: { [key: string]: any },
) {
  const { ipId: param0, ...queryParams } = params;
  return request<API.WebResultIpDetailAllVo>(`/api/ip/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 删除IP DELETE /api/ip/${param0} */
export async function ipByIpIdDelete(
  params: {
    // path
    /** ipId */
    ipId: number;
  },
  options?: { [key: string]: any },
) {
  const { ipId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/ip/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 更新IP基本信息（城市、时区、语言、描述、经纬度） locationId未设置时，后端可能需要根据countryCode、provinceCode创建IpLocation PUT /api/ip/${param0}/basicInfo */
export async function ipByIpIdBasicInfoPut(
  params: {
    // query
    /** locationId */
    locationId?: number;
    /** countryCode */
    countryCode?: string;
    /** provinceCode */
    provinceCode?: string;
    /** timezone */
    timezone: string;
    /** locale */
    locale: string;
    /** latitude */
    latitude?: number;
    /** longitude */
    longitude?: number;
    /** forbiddenLongLatitude */
    forbiddenLongLatitude?: boolean;
    /** name */
    name?: string;
    /** 描述 */
    description?: string;
    /** networkType */
    networkType?: 'cloudIdc' | 'mobile' | 'proxyIdc' | 'residential' | 'unknown' | 'unknownIdc';
    /** dynamic */
    dynamic?: boolean;
    // path
    /** ipId */
    ipId: number;
  },
  options?: { [key: string]: any },
) {
  const { ipId: param0, ...queryParams } = params;
  return request<API.WebResultIpLocationDto>(`/api/ip/${param0}/basicInfo`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** ip详情页面将ip绑定到账户之前，先检查一下是否有警告信息 GET /api/ip/${param0}/bindHisInfo */
export async function ipByIpIdBindHisInfoGet(
  params: {
    // query
    /** 访问次数按多少个月统计 */
    month: number;
    // path
    /** ipId */
    ipId: number;
  },
  options?: { [key: string]: any },
) {
  const { ipId: param0, ...queryParams } = params;
  return request<API.WebResultMapstringIpForShopBindVo>(`/api/ip/${param0}/bindHisInfo`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 绑定账户历史 GET /api/ip/${param0}/bindHistory */
export async function ipByIpIdBindHistoryGet(
  params: {
    // query
    /** platformType */
    platformType: string;
    // path
    /** ipId */
    ipId: number;
  },
  options?: { [key: string]: any },
) {
  const { ipId: param0, ...queryParams } = params;
  return request<API.WebResultListIpBindDto>(`/api/ip/${param0}/bindHistory`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取ip绑定账户历史记录 GET /api/ip/${param0}/bindShopHistory */
export async function ipByIpIdBindShopHistoryGet(
  params: {
    // path
    /** ipId */
    ipId: number;
  },
  options?: { [key: string]: any },
) {
  const { ipId: param0, ...queryParams } = params;
  return request<API.WebResultListIpBindHistoryVo>(`/api/ip/${param0}/bindShopHistory`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 修改IP为按月续费 PUT /api/ip/${param0}/chargeByMonth */
export async function ipByIpIdChargeByMonthPut(
  params: {
    // path
    /** ipId */
    ipId: number;
  },
  options?: { [key: string]: any },
) {
  const { ipId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/ip/${param0}/chargeByMonth`, {
    method: 'PUT',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 修改IP的IP值 前端确保IP是正确的 PUT /api/ip/${param0}/ip */
export async function ipByIpIdIpPut(
  params: {
    // query
    /** ip */
    ip: string;
    // path
    /** ipId */
    ipId: number;
  },
  options?: { [key: string]: any },
) {
  const { ipId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/ip/${param0}/ip`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 更新IP的名称 PUT /api/ip/${param0}/name */
export async function ipByIpIdNamePut(
  params: {
    // query
    /** name */
    name: string;
    // path
    /** ipId */
    ipId: number;
  },
  options?: { [key: string]: any },
) {
  const { ipId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/ip/${param0}/name`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 修改IP的Provider和Dynamic 前端确保IP是正确的 PUT /api/ip/${param0}/providerAndDynamic */
export async function ipByIpIdProviderAndDynamicPut(
  params: {
    // query
    /** provider */
    provider?: string;
    /** dynamic */
    dynamic?: boolean;
    /** refreshUrl */
    refreshUrl?: string;
    // path
    /** ipId */
    ipId: number;
  },
  options?: { [key: string]: any },
) {
  const { ipId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/ip/${param0}/providerAndDynamic`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** IP与中转探测详情 GET /api/ip/${param0}/transits */
export async function ipByIpIdTransitsGet(
  params: {
    // path
    /** ipId */
    ipId: number;
  },
  options?: { [key: string]: any },
) {
  const { ipId: param0, ...queryParams } = params;
  return request<API.WebResultListIpTransitDto>(`/api/ip/${param0}/transits`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}
