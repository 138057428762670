import I18N from '@/i18n';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ErrorCatcher from 'react-error-catcher';
import { useLocation, UseRequestProvider, useRouteMatch } from 'umi';
import { Button, Input, message, Typography } from 'antd';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import styles from '@/app.less';
import buttonStyles from '@/style/button.less';
import colors from '@/style/color.less';
import { getJwt, getProductSiteLink, isWinPlatform, setJwt } from '@/utils/utils';
import { addEventListener, isElectron, sendAsync } from '@/utils/ElectronUtils';
import constants from '@/constants';
import IconFontIcon from '@/components/Common/IconFontIcon';
import MessageCenter from '@/components/MessageCenter';
import { openInNewWindow, openOfficialSiteByAppWindow } from '@/utils/pageUtils';
import { useModel } from '@@/plugin-model/useModel';
import useSysBroadcast from '@/hooks/useSysBroadcast';
import ErrorBlock from '../Common/ErrorBlock';
import DMConfirm, { DMLoading } from '@/components/Common/DMConfirm';
import HelpLink from '@/components/HelpLink';
import { accountRefreshTokenGet as refreshToken } from '@/services/api-Account/LoginController';
import useWindowSync from '@/hooks/useWindowSync';
import { getSysPresSettings, setSysPresSettings } from '@/i18n/I18N';
import { DMContextProvider } from '@/components/Common/Modal/DMModal';
import { createPortal } from 'react-dom';
import SwitchLocale from '@/i18n/SwitchLocale';
import ColoursIcon from '@/components/Common/ColoursIcon';
import useAppCheck from '@/hooks/useAppCheck';

/**
 * 不显示顶部条的路由path
 */
const hidePaths = [
  '/login',
  '/register',
  '/findPassword',
  '/selectTeam',
  '/wx',
  '/fingerprint',
  '/link/invite-join-team',
  '/rpaCaptcha',
  '/rpaSelectorPicker',
  '/preview',
  '/rpaTaskMonitor',
  '/result',
  '/ipMarket',
  '/shortcut',
];

const Index: React.FC = (props) => {
  const { pathname } = useLocation();
  const runTaskRouter = useRouteMatch('/team/:teamId/runTask/:flowId');
  const phonePopupRouter = useRouteMatch('/team/:teamId/phonePopup/:phoneId');
  const windowSyncToolboxRouter = useRouteMatch('/windowSyncToolbox');
  const { initialState } = useModel('@@initialState');
  const { sessions, master, follows } = useModel('windowSync');
  const [loadJSError, setLoadJsError] = useState('');
  const { fetchGcDetail, showWindowSyncConfirm } = useWindowSync();
  const browserLaunchErrorCountRef = useRef(0);
  useSysBroadcast();
  const { checkAppCompatibility, showNewVersionToInstallConfirm } = useAppCheck();

  // 需要在页面顶部提示"非客户端环境"的路由
  const [envTipsBarVisible, setEnvTipsBarVisible] = useState(
    !isElectron() &&
      process.env.UMI_ENV !== 'browser' &&
      localStorage.getItem('hideEvnTipsBar') !== '1' &&
      !hidePaths.some((i) => pathname.includes(i)),
  );
  const appErrMsgVisible = useRef(false);

  useEffect(() => {
    checkAppCompatibility();
  }, [checkAppCompatibility]);

  const showLocaleSwitch = useMemo(() => {
    return (
      !!document.querySelector('#window-title-bar-lang-selector') &&
      !runTaskRouter &&
      !windowSyncToolboxRouter &&
      !phonePopupRouter
    );
  }, [runTaskRouter, windowSyncToolboxRouter, phonePopupRouter]);

  useEffect(() => {
    window.addEventListener(
      'error',
      (event) => {
        if (event?.target?.tagName === 'SCRIPT') {
          // 处理资源加载失败的情况，例如重新加载或显示错误消息
          if (!/^chrome-extension:\/\//.test(event.target.src)) {
            setLoadJsError(event.target.src);
          }
        }
      },
      true,
    );
  }, []);

  useEffect(() => {
    if (!isElectron()) return;
    sendAsync('has-new-version-to-install', {}).then((hasNewVersion: boolean) => {
      if (hasNewVersion) {
        showNewVersionToInstallConfirm();
      }
    });
    addEventListener('app-new-version-ready-to-install', () => {
      showNewVersionToInstallConfirm();
    });
    addEventListener('reload-page', () => {
      window.location.reload();
    });
  }, []);

  useEffect(() => {
    // APP版本检测
    if (!isElectron() || pathname.indexOf('/team') !== 0) return () => {};
    const sysPresSettings = getSysPresSettings();
    if (!sysPresSettings.hideNewVersionAlert && sysPresSettings.autoUpdate === 0b00) {
      sendAsync('get-app-build-number').then((appBuildNumber) => {
        const hasNewAppVersion =
          Number(appBuildNumber) < Number(initialState?.versionConfig?.currentBuildNo);
        if (hasNewAppVersion) {
          message.info({
            content: (
              <span>
                {I18N.t('版本升级提示：花漾客户端有最新版本，请您尽快升级')}

                <a
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    DMConfirm({
                      iconType: 'info',
                      title: I18N.t('有最新版本需要更新'),
                      content: `${I18N.t('有新版本')}${
                        initialState?.versionConfig?.appVersion
                          ? ` ${initialState?.versionConfig?.appVersion} `
                          : ''
                      }${I18N.t('可以更新，请下载最新版本后，重新安装即可（原有数据将完整保留）')}`,

                      okText: I18N.t('立即下载'),
                      onOk: () => {
                        openOfficialSiteByAppWindow(constants.appDownloadLinkFromApp);
                      },
                    });
                  }}
                  target="_blank"
                >
                  {I18N.t('了解更多')}
                </a>
                <a
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    setSysPresSettings({ ...getSysPresSettings(), hideNewVersionAlert: true });
                    message.destroy('newAppVersion');
                  }}
                >
                  {I18N.t('下次再说')}
                </a>
                <IconFontIcon
                  iconName="guanbi_24"
                  style={{ marginLeft: 20, cursor: 'pointer' }}
                  onClick={() => message.destroy('newAppVersion')}
                />
              </span>
            ),

            duration: 0,
            key: 'newAppVersion',
            className: styles.newAppVersionTips,
          });
        }
      });
    }
    // APP异常监听
    const removeListener = addEventListener('app-error-report', (evt, data) => {
      if (!appErrMsgVisible.current) {
        let msg = `${I18N.t('错误号：')}${data.uuid}\n`;
        msg += `${data.error?.stack}\n`;
        if (data.errorCode === 'browserLaunchError') {
          browserLaunchErrorCountRef.current++;
          if (browserLaunchErrorCountRef.current < 2) {
            // 第一次，提示修复浏览器内核（重新解压）
            DMConfirm({
              iconType: 'info',
              width: 550,
              title: I18N.t('系统遇到了异常情况'),
              content: (
                <div style={{ fontSize: 14 }}>
                  <div style={{ marginBottom: 4 }}>
                    {I18N.t('花漾浏览器引擎文件可能不完整，请您按照以下步骤进行修复：')}
                  </div>
                  <div style={{ marginBottom: 4, color: colors.primaryColor }}>
                    {I18N.t('1.如果电脑打开了360安全卫士等杀毒软件，请将其临时关闭')}
                  </div>
                  <div style={{ color: colors.primaryColor }}>
                    {I18N.t('2.关闭上述杀毒软件后，请点击“立即修复”')}
                  </div>
                </div>
              ),

              okText: I18N.t('立即修复'),
              onOk: () => {
                const repairingLoadingModal = DMLoading({
                  title: I18N.t('正在为您进行修复，请稍候'),
                  content: (
                    <div style={{ fontSize: 14 }}>{I18N.t('这可能需要一点时间，请稍候')}</div>
                  ),
                });
                const timeout = setTimeout(() => {
                  repairingLoadingModal.destroy();
                  DMConfirm({
                    type: 'error',
                    title: I18N.t('修复花漾浏览器引擎文件超时'),
                    content: (
                      <div style={{ fontSize: 14 }}>
                        {I18N.t('建议您下载最新版本，重新安装')}

                        <a
                          style={{ marginLeft: 16 }}
                          onClick={() =>
                            openOfficialSiteByAppWindow(constants.appDownloadLinkFromApp)
                          }
                        >
                          {I18N.t('立即下载')}
                        </a>
                      </div>
                    ),
                  });
                }, 60 * 1000);
                sendAsync('unzip-browser', { kernelVersion: data.kernelVersion })
                  .then(() => {
                    clearTimeout(timeout);
                    repairingLoadingModal.destroy();
                    DMConfirm({
                      type: 'success',
                      title: I18N.t('系统已完成修复'),
                      content: (
                        <div style={{ fontSize: 14 }}>
                          {I18N.t('请重新访问分身，验证是否修复成功')}
                        </div>
                      ),
                    });
                  })
                  .catch((err) => {
                    DMConfirm({
                      type: 'error',
                      title: I18N.t('修复花漾浏览器引擎文件失败'),
                      content: <div style={{ fontSize: 14 }}>{err.message}</div>,
                    });
                  });
              },
            });
            return;
          }
        }
        appErrMsgVisible.current = true;
        DMConfirm({
          type: 'error',
          width: 600,
          title: I18N.t('非常抱歉，系统遇到了中断性错误'),
          content: (
            <div style={{ fontSize: 14 }}>
              <div style={{ marginBottom: 4 }}>
                {data.shopName
                  ? I18N.t('打开分身“{{shopName}}"出现错误，原因可能是：', {
                      shopName: data.shopName,
                    })
                  : I18N.t('造成此错误的原因通常是因为：')}
              </div>
              <div style={{ marginBottom: 4, color: colors.primaryColor }}>
                {I18N.t(
                  '1.该分身所对应的花漾浏览器未正常关闭，请打开任务管理器，强制结束所有名称为“{{PROCESS_NAME}}"的进程，再重新打开此分身',
                  { PROCESS_NAME: isWinPlatform() ? 'chrome.exe' : 'Chromium' },
                )}
              </div>
              <div style={{ marginBottom: 4, color: colors.primaryColor }}>
                {I18N.t('2.花漾浏览器内核未正常解压，请重启花漾客户端，再重新打开此分身')}
              </div>
              <div style={{ marginBottom: 4 }}>
                {I18N.t('如果依然无法解决，请复制下述错误信息，并反馈给在线客服：')}
              </div>
              <div>
                <Input.TextArea
                  id="app-err-msg-textarea"
                  value={msg}
                  style={{ fontSize: 12, resize: 'none', height: 70 }}
                />
              </div>
              <div style={{ textAlign: 'right' }}>
                <a
                  onClick={() => {
                    copy(msg);
                    // @ts-ignore
                    document.getElementById('app-err-msg-textarea')?.select();
                    message.success(I18N.t('错误信息复制成功'));
                  }}
                >
                  {I18N.t('复制')}
                </a>
              </div>
            </div>
          ),

          footerBtns: [
            {
              content: (
                <Button
                  type="primary"
                  onClick={() => {
                    openOfficialSiteByAppWindow(constants.appDownloadLinkFromApp);
                    appErrMsgVisible.current = false;
                  }}
                >
                  {I18N.t('重新下载')}
                </Button>
              ),
            },
            {
              key: 'ok',
              label: I18N.t('在线客服'),
              onClick: () => {
                openInNewWindow(constants.qdSupportLink);
                appErrMsgVisible.current = false;
              },
              btnProps: { className: buttonStyles.successBtn },
            },
            {
              key: 'cancel',
              label: I18N.t('关闭'),
              onClick: () => {
                appErrMsgVisible.current = false;
              },
            },
          ],
        });
      }
    });

    window.addEventListener('online', async () => {
      if (navigator.onLine) {
        // 网络恢复后，尝试刷新 jwt
        try {
          if (getJwt()) {
            const rs = await refreshToken();
            if (rs.success && rs.data) {
              setJwt(rs.data.jwt!);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    const removeListener1 = addEventListener('gc/event/winSizeChange', (evt, data) => {
      showWindowSyncConfirm({
        title: I18N.t('浏览器窗口大小发生了变化，这会导致群控出错，请对窗口重新布局'),
        sessions,
        master,
        follows,
      });
    });
    const removeListener2 = addEventListener('gc/event/detailUpdate', (evt, data) => {
      fetchGcDetail();
    });
    return () => {
      removeListener1();
      removeListener2();
    };
  }, [fetchGcDetail, sessions, master, follows, showWindowSyncConfirm]);

  if (loadJSError) {
    return (
      <ErrorBlock
        error={I18N.t('加载资源文件失败')}
        subTitle={I18N.t('加载资源文件 {{path}} 失败', { path: loadJSError })}
      />
    );
  }

  return (
    <ErrorCatcher
      errorRender={<ErrorBlock error={new Error(I18N.t('出错了'))} />}
      onCatch={(errors) => {
        console.error(errors);
      }}
    >
      <UseRequestProvider
        value={{
          throttleInterval: 500,
        }}
      >
        {envTipsBarVisible && (
          <div className={styles.envTipsBar}>
            {I18N.t(
              '您正在访问我们的应用站点，建议您下载并安装“花漾客户端”，否则您将无法使用访问分身以打开花漾浏览器等功能。',
            )}

            <Typography.Link
              underline
              href={getProductSiteLink(constants.appDownloadLink)}
              target="_blank"
            >
              {I18N.t('立即下载')}
            </Typography.Link>
            <HelpLink href={'/app/brief'} style={{ textDecoration: 'underline' }} />
            <span className={styles.btnAction}>
              <IconFontIcon
                iconName="guanbi_24"
                onClick={() => {
                  setEnvTipsBarVisible(false);
                  localStorage.setItem('hideEvnTipsBar', '1');
                }}
              />
            </span>
          </div>
        )}

        <div
          className={classNames(styles.contentWrap, {
            'has-global-tips-bar': envTipsBarVisible,
          })}
        >
          <DMContextProvider>
            {props.children}
            <MessageCenter />
            {showLocaleSwitch &&
              createPortal(
                <SwitchLocale
                  currentRender={() => (
                    <a>
                      <ColoursIcon className="yuyan_24" size={16} />
                    </a>
                  )}
                  onChange={(val) => {
                    if (val === 'en-US') {
                      I18N.switchToEn();
                    } else {
                      I18N.switchToCn();
                    }
                  }}
                />,
                document.querySelector('#window-title-bar-lang-selector')!,
              )}
          </DMContextProvider>
        </div>
      </UseRequestProvider>
    </ErrorCatcher>
  );
};

export default Index;
