// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 检查手机名称是否已经存在 GET /api/shop/mobile/checkNameExists */
export async function shopMobileCheckNameExistsGet(
  params: {
    // query
    /** name */
    name: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultboolean>('/api/shop/mobile/checkNameExists', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 统计当前用户在当前团队有权限的手机个数 GET /api/shop/mobile/countAuthorizedMobiles */
export async function shopMobileCountAuthorizedMobilesGet(
  params: {
    // query
    /** 是否只统计联营的手机 */
    sharedOnly?: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultint>('/api/shop/mobile/countAuthorizedMobiles', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 导入一个手机 POST /api/shop/mobile/create */
export async function shopMobileCreatePost(
  body: API.CreateMobileRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultTeamMobileVo>('/api/shop/mobile/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 清空一个设备下的所有手机 DELETE /api/shop/mobile/deleteDeviceMobiles */
export async function shopMobileDeleteDeviceMobilesDelete(
  params: {
    // query
    /** deviceId */
    deviceId: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/mobile/deleteDeviceMobiles', {
    method: 'DELETE',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 删除一个手机 DELETE /api/shop/mobile/deleteMobile/${param0} */
export async function shopMobileDeleteMobileByIdDelete(
  params: {
    // path
    /** id */
    id: number;
  },
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/mobile/deleteMobile/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 查询团队的手机所在的客户端列表 GET /api/shop/mobile/devices */
export async function shopMobileDevicesGet(options?: { [key: string]: any }) {
  return request<API.WebResultListLoginDeviceDto>('/api/shop/mobile/devices', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取一个手机的简易信息 GET /api/shop/mobile/getSimpleInfo */
export async function shopMobileGetSimpleInfoGet(
  params: {
    // query
    /** id */
    id: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultTeamMobileSimpleVo>('/api/shop/mobile/getSimpleInfo', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 将手机授权给用户和组织，逻辑和授权分身给用户/组织的逻辑一模一样 POST /api/shop/mobile/grantMobileToUsers */
export async function shopMobileGrantMobileToUsersPost(
  body: API.jiangfenshenshouquangeiyonghuhezuzhi,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/mobile/grantMobileToUsers', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询团队的手机分组列表 GET /api/shop/mobile/groups */
export async function shopMobileGroupsGet(options?: { [key: string]: any }) {
  return request<API.WebResultListMobileGroupVo>('/api/shop/mobile/groups', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 对于非当前app直接连接的手机，发起一个webrtc room以便获取视频流，返回一个roomId PUT /api/shop/mobile/initiateVideoRoom/${param0} */
export async function shopMobileInitiateVideoRoomByIdPut(
  params: {
    // path
    /** id */
    id: number;
  },
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.WebResultMap>(`/api/shop/mobile/initiateVideoRoom/${param0}`, {
    method: 'PUT',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 查询团队的手机列表，deviceId和groupId同时不为空会忽略groupId GET /api/shop/mobile/list */
export async function shopMobileListGet(
  params: {
    // query
    /** 如果不为空表示只查这个login_device连接的手机 */
    deviceId?: string;
    /** 如果不为空表示只查这个group的手机 */
    groupId?: number;
    /** 是否带上手机标签 */
    fetchTags?: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListTeamMobileVo>('/api/shop/mobile/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询当前团队被联营的手机列表 GET /api/shop/mobile/list/shared */
export async function shopMobileListSharedGet(options?: { [key: string]: any }) {
  return request<API.WebResultListTeamMobileVo>('/api/shop/mobile/list/shared', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 重启某个客户端的adb server PUT /api/shop/mobile/restartAdbServer */
export async function shopMobileRestartAdbServerPut(
  params: {
    // query
    /** deviceId */
    deviceId: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/mobile/restartAdbServer', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 更新手机基本信息 POST /api/shop/mobile/update */
export async function shopMobileUpdatePost(
  body: API.UpdateMobileRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultTeamMobileVo>('/api/shop/mobile/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 更改手机的 message sync 配置 POST /api/shop/mobile/updateAccountMCConfig */
export async function shopMobileUpdateAccountMCConfigPost(
  body: API.UpdateAccountMCConfigRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/mobile/updateAccountMCConfig', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 设置是否暂时禁用rpa执行 PUT /api/shop/mobile/updateRpaSilent */
export async function shopMobileUpdateRpaSilentPut(
  params: {
    // query
    /** mobileId */
    mobileId: number;
    /** rpaSilent */
    rpaSilent: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/mobile/updateRpaSilent', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 将手机加入分组 PUT /api/shop/mobile/group/addMobiles */
export async function shopMobileGroupAddMobilesPut(
  body: API.AddMobileToGroupRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/mobile/group/addMobiles', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 检查分组名称是否已经存在 GET /api/shop/mobile/group/checkNameExists */
export async function shopMobileGroupCheckNameExistsGet(
  params: {
    // query
    /** name */
    name: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultboolean>('/api/shop/mobile/group/checkNameExists', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 创建一个自定义的手机分组 POST /api/shop/mobile/group/create */
export async function shopMobileGroupCreatePost(
  body: API.CreateMobileGroupRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultMobileGroupVo>('/api/shop/mobile/group/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除一个自定义的手机分组 DELETE /api/shop/mobile/group/delete */
export async function shopMobileGroupDeleteDelete(
  params: {
    // query
    /** groupId */
    groupId: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/mobile/group/delete', {
    method: 'DELETE',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 将手机从分组中移除 POST /api/shop/mobile/group/removeMobiles */
export async function shopMobileGroupRemoveMobilesPost(
  body: API.RemoveMobileFromGroupRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/mobile/group/removeMobiles', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改一个自定义的手机分组(名称or排序) POST /api/shop/mobile/group/update */
export async function shopMobileGroupUpdatePost(
  body: API.UpdateMobileGroupRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultMobileGroupVo>('/api/shop/mobile/group/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
