import I18N from '@/i18n';
import type { FC } from 'react';
import { useCallback, useEffect, useState, useRef } from 'react';
import { Button, message as antMessage, Space, Typography } from 'antd';
import moment from 'moment';
import { history } from 'umi';

import styles from './messageDetail.less';
import {
  msgCenterMarkHasReadPut,
  msgCenterMessagesGet,
} from '@/services/api-MessageCenterAPI/MessageCenterController';
import { useModel } from '@@/plugin-model/useModel';
import MessageCenterConstants from '@/constants/MessageCenterConstants';
import IconFontIcon from '@/components/Common/IconFontIcon';
import IpProductDetail from '@/components/MessageCenter/components/IpProductDetail';
import UserAvatarAndName from '@/components/Common/UserAvatarAndName';
import OrderDetailTable from '@/components/MessageCenter/components/OrderDetailTable';
import { openInNewWindow } from '@/utils/pageUtils';
import DomainAuditDetail from '@/components/MessageCenter/components/DomainAuditDetail';
import ShopSharingDetail from '@/components/MessageCenter/components/ShopSharingDetail';
import ActivityDetail from '@/components/MessageCenter/components/ActivityDetail';
import {
  AnnouncementDetail,
  CriticalInspectionDetail,
} from '@/components/LeftMenu/CriticalInspection';
import { Device } from '@/components/UserCenter/components/Devices';
import LabelRow, { LabelRowContext } from '@/components/Common/LabelRow';
import MobileSharingDetail from '@/components/MessageCenter/components/MobileSharingDetail';
import { sendAsync } from '@/utils/ElectronUtils';
import { getPortalUrl } from '@/utils/utils';
import { shopMobileGetSimpleInfoGet } from '@/services/api-ShopAPI/TeamMobileGroupController';
import constants from '@/constants';

type Props = {
  message: API.UserMessageVo | null;
  setCurrentMessage: (message: API.UserMessageVo) => void;
  onClose: () => void;
  reloadMessageList: () => void;
};

/**
 * 消息详情面板
 * @param props
 * @constructor
 */
const MessageDetail: FC<Props> = (props) => {
  const { message: currentMessage, setCurrentMessage, onClose, reloadMessageList } = props;
  const { currentTeam } = useModel('currentTeam');
  const { hide } = useModel('messageCenter');
  const [nextUnreadMessage, setNextUnreadMessage] = useState<API.UserMessageVo | null>(null);
  const needRefreshList = useRef(false);

  useEffect(() => {
    if (!currentMessage) return;
    (async () => {
      if (!currentMessage.readTime) {
        // 标为已读
        await msgCenterMarkHasReadPut({ ids: currentMessage.id! });
        needRefreshList.current = true;
      }
      const { success, data } = await msgCenterMessagesGet({
        pageNum: 1,
        pageSize: 1,
        unread: true,
      });
      if (success && data) {
        setNextUnreadMessage(data.list?.[0] ?? null);
      }
    })();
    // eslint-disable-next-line consistent-return
    return () => {
      if (needRefreshList.current) {
        reloadMessageList();
      }
    };
  }, [currentMessage, reloadMessageList]);

  const goto = useCallback(
    (path) => {
      const basePath = `/team/${currentTeam?.id}`;
      history.push(basePath + path);
      onClose();
      hide();
    },
    [currentTeam?.id, hide, onClose],
  );

  const getTitle = useCallback((message: API.UserMessageVo) => {
    switch (message.messageType) {
      case 'Activity':
      case 'Critical_Message':
      case 'OP_Message':
        return message.summary;
      default:
        return MessageCenterConstants.messageTypeNameMap[message.messageType!];
    }
  }, []);

  /**
   * 消息体
   */
  const getContentBody = useCallback(
    (message: API.UserMessageVo) => {
      let regRes = null;
      let parameters: any = {};
      let userId: number;
      try {
        parameters = JSON.parse(message.parameters ?? '{}');
      } catch (e) {
        console.error(e);
      }
      switch (message.messageType) {
        case 'Ip_Product_Start':
        case 'Ip_Product_Success':
        case 'Ip_Product_Failed':
          return <IpProductDetail message={message} goto={goto} />;
        case 'Ip_Will_Expire':
        case 'Ip_Expire':
        case 'Ip_Import':
        case 'Ip_UnImport':
        case 'Ip_Renew_Success':
        case 'Ip_Renew_Failed':
        case 'Ip_Will_Destroy':
          regRes = message.content?.match(/^(.*“)(.+)(”.*)$/);
          if (regRes) {
            const [content, prefix, ip, suffix] = regRes;
            return (
              <span title={content}>
                {prefix}
                <a onClick={() => goto(`/ipManage/all/${message.resourceId}`)}>
                  {parameters.ip ?? ip}
                </a>
                {suffix}
              </span>
            );
          }
          return message.content;
        case 'Ip_Address_Changed':
          return (
            <>
              {message.content}
              <a onClick={() => goto(`/ipManage/all/${message.resourceId}`)}>
                {I18N.t('查看IP详情')}
              </a>
            </>
          );

        case 'Shop_Transfer_Audit_Notify':
          return (
            <>
              {message.content}
              <a onClick={() => goto('/teamManage/audit')} style={{ marginLeft: 20 }}>
                {I18N.t('查看详情')}
              </a>
            </>
          );
        case 'Kol_New_TK_Message':
          return (
            <>
              {message.content}
              <a
                onClick={() => {
                  shopMobileGetSimpleInfoGet({ id: message.resourceId! }).then((res) => {
                    const getScreenInfo = (mobileCode: string) => {
                      const videoSettingsStr = localStorage.getItem(`screenInfo:${mobileCode}`);
                      if (videoSettingsStr) {
                        return JSON.parse(videoSettingsStr);
                      }
                      return {
                        w: 640,
                        h: 1280,
                      };
                    };
                    if (res.data?.status === 'OFFLINE') {
                      antMessage.warn(I18N.t('手机已离线'));
                      return;
                    }
                    const screenInfo = getScreenInfo(res.data?.code ?? '');
                    sendAsync('popup-mobile', {
                      phoneId: message.resourceId,
                      url: getPortalUrl(
                        `/team/${currentTeam?.id}/phonePopup/${message.resourceId}`,
                      ),
                      width: Math.floor(screenInfo.w / 2) + 44 + 10 + 12 * 2,
                      height:
                        Math.floor(screenInfo.h / 2) +
                        30 +
                        10 +
                        window.navigator.windowControlsOverlay.getTitlebarAreaRect().height +
                        86,
                    });
                  });
                }}
                style={{ marginLeft: 20 }}
              >
                {I18N.t('打开手机查看详情')}
              </a>
            </>
          );
        case 'User_New_Device':
          userId = parameters.applyUserId;
          // eslint-disable-next-line no-case-declarations
          const { device } = parameters;
          // eslint-disable-next-line no-case-declarations
          const { lastRemoteIp, ipData } = device;
          return (
            <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
              <LabelRowContext.Provider value={{ labelMuted: false }}>
                <LabelRow label={I18N.t('用户')}>
                  <UserAvatarAndName
                    user={{ id: userId, nickname: parameters.nickname }}
                    style={{ display: 'inline-flex', verticalAlign: 'middle', marginRight: 5 }}
                  />
                </LabelRow>
                <LabelRow label={I18N.t('登录设备')}>
                  <Device data={parameters.device} />
                </LabelRow>
                <LabelRow label={I18N.t('设备ID')}>{device.deviceId}</LabelRow>
                {lastRemoteIp && (
                  <LabelRow label={I18N.t('登录地点')}>
                    {`${lastRemoteIp}（${ipData?.city || '未知'}）`}
                  </LabelRow>
                )}
              </LabelRowContext.Provider>

              <div>
                <Button
                  type={'primary'}
                  ghost
                  onClick={() => {
                    goto(`/teamManage/audit`);
                  }}
                >
                  {I18N.t('立即审批')}
                </Button>
              </div>
            </div>
          );
        case 'User_Join_Team_Audit_Notify':
          regRes = message.content?.match(/^(.*)(“.*”)(.*)$/);
          if (regRes) {
            const [content, prefix, nickname = '', suffix] = regRes;
            // @ts-ignore
            userId = parameters.applyUserId;
            return (
              <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
                <span title={content}>
                  {prefix}
                  <a onClick={() => goto(`/teamManage/audit`)}>
                    <UserAvatarAndName
                      user={{ id: userId, nickname: nickname.replace(/^“(.*)”$/, '$1') }}
                      style={{ display: 'inline-flex', verticalAlign: 'middle', marginRight: 5 }}
                    />
                  </a>
                  {suffix}
                </span>
                <div>
                  <Button
                    type={'primary'}
                    ghost
                    onClick={() => {
                      goto(`/teamManage/audit`);
                    }}
                  >
                    {I18N.t('立即审批')}
                  </Button>
                </div>
              </div>
            );
          }
          return message.content;
        case 'User_Join_Team':
        case 'User_Exit_team':
          regRes = message.content?.match(/^(.*)(“.*”)(.*)$/);
          if (regRes) {
            const [content, prefix, nickname = '', suffix] = regRes;
            userId = message.resourceId!;
            const avatar = (
              <UserAvatarAndName
                user={{ id: userId, nickname: nickname.replace(/^“(.*)”$/, '$1') }}
                style={{ display: 'inline-flex', verticalAlign: 'middle', marginRight: 5 }}
              />
            );

            return (
              <span title={content}>
                {prefix}
                {message.messageType === 'User_Join_Team' ? (
                  <a onClick={() => goto(`/teamManage/users`)}>{avatar}</a>
                ) : (
                  avatar
                )}

                {suffix}
              </span>
            );
          }
          return message.content;
        case 'Domain_White_Pass':
        case 'Domain_White_Reject':
          return <DomainAuditDetail message={message} />;
        case 'Bank_Pay_Confirmed':
          regRes = message.content?.match(/^(.*“)(.*)(”.*)$/);
          if (regRes) {
            const [content, , serialNumber = ''] = regRes;
            return (
              <>
                <p>{content}</p>
                <OrderDetailTable message={message} goto={goto} serialNumber={serialNumber} />
              </>
            );
          }
          return message.content;
        case 'New_Order_Need_Pay':
          return <OrderDetailTable message={message} goto={goto} />;
        case 'Credit_Buy_Notify':
          return (
            <>
              <p>{message.content}</p>
              {parameters.success === false && <CustomServiceRow />}
              <OrderDetailTable message={message} goto={goto} />
            </>
          );

        case 'Shop_Info_ReportSuccess':
          return (
            <>
              {message.content}
              <a
                onClick={() => goto(`/shopManage/all/${message.resourceId}`)}
                style={{ marginLeft: 20 }}
              >
                {I18N.t('查看详情')}
              </a>
            </>
          );

        case 'Shop_Receive_Notify':
        case 'Shop_Receive_Result':
        case 'Shop_Sharing_Notify':
        case 'Shop_Sharing_Result':
          return <ShopSharingDetail message={message} />;
        case 'Activity':
          return (
            <ActivityDetail
              message={message}
              goto={goto}
              close={() => {
                onClose();
                hide();
              }}
            />
          );

        case 'Critical_Message':
          return <CriticalInspectionDetail scrollY={300} message={message} goto={goto} />;
        case 'OP_Message':
          return <AnnouncementDetail content={message.content} />;
        case 'Mobile_Sharing_Notify':
          return <MobileSharingDetail message={message} />;
        default:
          return message.content;
      }
    },
    [goto, hide, onClose],
  );

  if (!currentMessage) {
    return null;
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.nav}>
        <div>
          <a onClick={() => onClose()}>
            <Space size={4}>
              <IconFontIcon iconName="angle-double-left_24" size={12} />
              {I18N.t('返回列表')}
            </Space>
          </a>
        </div>
        <div>
          {nextUnreadMessage && (
            <Button ghost type="primary" onClick={() => setCurrentMessage(nextUnreadMessage)}>
              {I18N.t('下一条未读')}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <Typography.Title level={3} ellipsis={{ tooltip: getTitle(currentMessage) }}>
            {getTitle(currentMessage)}
          </Typography.Title>
          <Typography.Text type="secondary">
            {moment(currentMessage.createTime).format('YYYY-MM-DD HH:mm')}
          </Typography.Text>
        </div>
        <div className={styles.contentBody}>{getContentBody(currentMessage)}</div>
      </div>
    </div>
  );
};

export function CustomServiceRow() {
  return (
    <p>
      {I18N.t('如需了解进一步信息，请您')}

      <a style={{ marginLeft: 20 }}>
        <Space
          className="custom-service-item-sub-value custom-service-item-sub-link"
          onClick={() => openInNewWindow(constants.qdSupportLink)}
        >
          <IconFontIcon iconName="kefu_24" />
          {I18N.t('联络在线客服')}
        </Space>
      </a>
    </p>
  );
}

export default MessageDetail;
