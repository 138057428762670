// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 查询某个资源标签列表 GET /api/resourceTags */
export async function resourceTagsGet(
  params: {
    // query
    /** resourceType */
    resourceType:
      | 'AK'
      | 'Activity'
      | 'Audit'
      | 'BlockElements'
      | 'Cloud'
      | 'CrsOrder'
      | 'CrsProduct'
      | 'DiskFile'
      | 'FingerPrint'
      | 'FingerPrintTemplate'
      | 'Gateway'
      | 'GhCreator'
      | 'GhUser'
      | 'GhVideoCreator'
      | 'GiftCardPack'
      | 'Invoice'
      | 'Ip'
      | 'IpPool'
      | 'IppIp'
      | 'KakaoAccount'
      | 'KakaoFriend'
      | 'KolCreator'
      | 'MobileAccount'
      | 'None'
      | 'Orders'
      | 'PluginTeamPack'
      | 'Record'
      | 'RpaFlow'
      | 'RpaTask'
      | 'RpaTaskItem'
      | 'RpaVoucher'
      | 'Shop'
      | 'ShopSession'
      | 'Tag'
      | 'TeamDiskRoot'
      | 'TeamMobile'
      | 'TkCreator'
      | 'TkTeamPack'
      | 'TrafficPack'
      | 'TunnelVps'
      | 'Users'
      | 'View'
      | 'Voucher';
    /** resourceId */
    resourceId: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListTagDto>('/api/resourceTags', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 创建一个标签 POST /api/tag */
export async function tagPost(
  params: {
    // query
    /** resourceType */
    resourceType:
      | 'AK'
      | 'Activity'
      | 'Audit'
      | 'BlockElements'
      | 'Cloud'
      | 'CrsOrder'
      | 'CrsProduct'
      | 'DiskFile'
      | 'FingerPrint'
      | 'FingerPrintTemplate'
      | 'Gateway'
      | 'GhCreator'
      | 'GhUser'
      | 'GhVideoCreator'
      | 'GiftCardPack'
      | 'Invoice'
      | 'Ip'
      | 'IpPool'
      | 'IppIp'
      | 'KakaoAccount'
      | 'KakaoFriend'
      | 'KolCreator'
      | 'MobileAccount'
      | 'None'
      | 'Orders'
      | 'PluginTeamPack'
      | 'Record'
      | 'RpaFlow'
      | 'RpaTask'
      | 'RpaTaskItem'
      | 'RpaVoucher'
      | 'Shop'
      | 'ShopSession'
      | 'Tag'
      | 'TeamDiskRoot'
      | 'TeamMobile'
      | 'TkCreator'
      | 'TkTeamPack'
      | 'TrafficPack'
      | 'TunnelVps'
      | 'Users'
      | 'View'
      | 'Voucher';
    /** 标签内容 */
    tag: string;
    /** 立即绑定资源ID */
    resourceId?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultTagDto>('/api/tag', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 删除一个标签 DELETE /api/tag */
export async function tagDelete(
  params: {
    // query
    /** resourceType */
    resourceType:
      | 'AK'
      | 'Activity'
      | 'Audit'
      | 'BlockElements'
      | 'Cloud'
      | 'CrsOrder'
      | 'CrsProduct'
      | 'DiskFile'
      | 'FingerPrint'
      | 'FingerPrintTemplate'
      | 'Gateway'
      | 'GhCreator'
      | 'GhUser'
      | 'GhVideoCreator'
      | 'GiftCardPack'
      | 'Invoice'
      | 'Ip'
      | 'IpPool'
      | 'IppIp'
      | 'KakaoAccount'
      | 'KakaoFriend'
      | 'KolCreator'
      | 'MobileAccount'
      | 'None'
      | 'Orders'
      | 'PluginTeamPack'
      | 'Record'
      | 'RpaFlow'
      | 'RpaTask'
      | 'RpaTaskItem'
      | 'RpaVoucher'
      | 'Shop'
      | 'ShopSession'
      | 'Tag'
      | 'TeamDiskRoot'
      | 'TeamMobile'
      | 'TkCreator'
      | 'TkTeamPack'
      | 'TrafficPack'
      | 'TunnelVps'
      | 'Users'
      | 'View'
      | 'Voucher';
    /** 标签内容 */
    tag: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/tag', {
    method: 'DELETE',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 批量打标签 POST /api/tag/resources */
export async function tagResourcesPost(
  params: {
    // query
    /** resourceType */
    resourceType:
      | 'AK'
      | 'Activity'
      | 'Audit'
      | 'BlockElements'
      | 'Cloud'
      | 'CrsOrder'
      | 'CrsProduct'
      | 'DiskFile'
      | 'FingerPrint'
      | 'FingerPrintTemplate'
      | 'Gateway'
      | 'GhCreator'
      | 'GhUser'
      | 'GhVideoCreator'
      | 'GiftCardPack'
      | 'Invoice'
      | 'Ip'
      | 'IpPool'
      | 'IppIp'
      | 'KakaoAccount'
      | 'KakaoFriend'
      | 'KolCreator'
      | 'MobileAccount'
      | 'None'
      | 'Orders'
      | 'PluginTeamPack'
      | 'Record'
      | 'RpaFlow'
      | 'RpaTask'
      | 'RpaTaskItem'
      | 'RpaVoucher'
      | 'Shop'
      | 'ShopSession'
      | 'Tag'
      | 'TeamDiskRoot'
      | 'TeamMobile'
      | 'TkCreator'
      | 'TkTeamPack'
      | 'TrafficPack'
      | 'TunnelVps'
      | 'Users'
      | 'View'
      | 'Voucher';
    /** resourceIds */
    resourceIds: string;
    /** tagIds */
    tagIds: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/tag/resources', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 批量撤销标签 DELETE /api/tag/resources */
export async function tagResourcesDelete(
  params: {
    // query
    /** resourceType */
    resourceType:
      | 'AK'
      | 'Activity'
      | 'Audit'
      | 'BlockElements'
      | 'Cloud'
      | 'CrsOrder'
      | 'CrsProduct'
      | 'DiskFile'
      | 'FingerPrint'
      | 'FingerPrintTemplate'
      | 'Gateway'
      | 'GhCreator'
      | 'GhUser'
      | 'GhVideoCreator'
      | 'GiftCardPack'
      | 'Invoice'
      | 'Ip'
      | 'IpPool'
      | 'IppIp'
      | 'KakaoAccount'
      | 'KakaoFriend'
      | 'KolCreator'
      | 'MobileAccount'
      | 'None'
      | 'Orders'
      | 'PluginTeamPack'
      | 'Record'
      | 'RpaFlow'
      | 'RpaTask'
      | 'RpaTaskItem'
      | 'RpaVoucher'
      | 'Shop'
      | 'ShopSession'
      | 'Tag'
      | 'TeamDiskRoot'
      | 'TeamMobile'
      | 'TkCreator'
      | 'TkTeamPack'
      | 'TrafficPack'
      | 'TunnelVps'
      | 'Users'
      | 'View'
      | 'Voucher';
    /** resourceIds */
    resourceIds: string;
    /** tagIds */
    tagIds: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/tag/resources', {
    method: 'DELETE',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 清空资源的所有标签 DELETE /api/tag/resources/all */
export async function tagResourcesAllDelete(
  params: {
    // query
    /** resourceType */
    resourceType:
      | 'AK'
      | 'Activity'
      | 'Audit'
      | 'BlockElements'
      | 'Cloud'
      | 'CrsOrder'
      | 'CrsProduct'
      | 'DiskFile'
      | 'FingerPrint'
      | 'FingerPrintTemplate'
      | 'Gateway'
      | 'GhCreator'
      | 'GhUser'
      | 'GhVideoCreator'
      | 'GiftCardPack'
      | 'Invoice'
      | 'Ip'
      | 'IpPool'
      | 'IppIp'
      | 'KakaoAccount'
      | 'KakaoFriend'
      | 'KolCreator'
      | 'MobileAccount'
      | 'None'
      | 'Orders'
      | 'PluginTeamPack'
      | 'Record'
      | 'RpaFlow'
      | 'RpaTask'
      | 'RpaTaskItem'
      | 'RpaVoucher'
      | 'Shop'
      | 'ShopSession'
      | 'Tag'
      | 'TeamDiskRoot'
      | 'TeamMobile'
      | 'TkCreator'
      | 'TkTeamPack'
      | 'TrafficPack'
      | 'TunnelVps'
      | 'Users'
      | 'View'
      | 'Voucher';
    /** resourceIds */
    resourceIds: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/tag/resources/all', {
    method: 'DELETE',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 批量打标签（用标签名） POST /api/tag/resources/byTag */
export async function tagResourcesByTagPost(
  params: {
    // query
    /** resourceType */
    resourceType:
      | 'AK'
      | 'Activity'
      | 'Audit'
      | 'BlockElements'
      | 'Cloud'
      | 'CrsOrder'
      | 'CrsProduct'
      | 'DiskFile'
      | 'FingerPrint'
      | 'FingerPrintTemplate'
      | 'Gateway'
      | 'GhCreator'
      | 'GhUser'
      | 'GhVideoCreator'
      | 'GiftCardPack'
      | 'Invoice'
      | 'Ip'
      | 'IpPool'
      | 'IppIp'
      | 'KakaoAccount'
      | 'KakaoFriend'
      | 'KolCreator'
      | 'MobileAccount'
      | 'None'
      | 'Orders'
      | 'PluginTeamPack'
      | 'Record'
      | 'RpaFlow'
      | 'RpaTask'
      | 'RpaTaskItem'
      | 'RpaVoucher'
      | 'Shop'
      | 'ShopSession'
      | 'Tag'
      | 'TeamDiskRoot'
      | 'TeamMobile'
      | 'TkCreator'
      | 'TkTeamPack'
      | 'TrafficPack'
      | 'TunnelVps'
      | 'Users'
      | 'View'
      | 'Voucher';
    /** resourceIds */
    resourceIds: string;
    /** tag */
    tag: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/tag/resources/byTag', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 批量撤销标签 DELETE /api/tag/resources/byTag */
export async function tagResourcesByTagDelete(
  params: {
    // query
    /** resourceType */
    resourceType:
      | 'AK'
      | 'Activity'
      | 'Audit'
      | 'BlockElements'
      | 'Cloud'
      | 'CrsOrder'
      | 'CrsProduct'
      | 'DiskFile'
      | 'FingerPrint'
      | 'FingerPrintTemplate'
      | 'Gateway'
      | 'GhCreator'
      | 'GhUser'
      | 'GhVideoCreator'
      | 'GiftCardPack'
      | 'Invoice'
      | 'Ip'
      | 'IpPool'
      | 'IppIp'
      | 'KakaoAccount'
      | 'KakaoFriend'
      | 'KolCreator'
      | 'MobileAccount'
      | 'None'
      | 'Orders'
      | 'PluginTeamPack'
      | 'Record'
      | 'RpaFlow'
      | 'RpaTask'
      | 'RpaTaskItem'
      | 'RpaVoucher'
      | 'Shop'
      | 'ShopSession'
      | 'Tag'
      | 'TeamDiskRoot'
      | 'TeamMobile'
      | 'TkCreator'
      | 'TkTeamPack'
      | 'TrafficPack'
      | 'TunnelVps'
      | 'Users'
      | 'View'
      | 'Voucher';
    /** resourceIds */
    resourceIds: string;
    /** tag */
    tag: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/tag/resources/byTag', {
    method: 'DELETE',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 批量打标签V2 POST /api/tag/resourcesV2 */
export async function tagResourcesV2Post(
  body: API.TagResourceRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/tag/resourcesV2', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 更新标签 可以将一个系统标签更新为非系统标签，从而删除 PUT /api/tag/${param0} */
export async function tagByTagIdPut(
  params: {
    // query
    /** 标签内容 */
    tag: string;
    /** 是否系统标签（不设置时不更新） */
    system?: boolean;
    // path
    /** tagId */
    tagId: number;
  },
  options?: { [key: string]: any },
) {
  const { tagId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/tag/${param0}`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 删除一个标签（按ID） DELETE /api/tag/${param0} */
export async function tagByTagIdDelete(
  params: {
    // path
    /** 标签ID */
    tagId: number;
  },
  options?: { [key: string]: any },
) {
  const { tagId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/tag/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 撤销某个资源的标签 DELETE /api/tag/${param0}/resource */
export async function tagByTagIdResourceDelete(
  params: {
    // query
    /** resourceType */
    resourceType:
      | 'AK'
      | 'Activity'
      | 'Audit'
      | 'BlockElements'
      | 'Cloud'
      | 'CrsOrder'
      | 'CrsProduct'
      | 'DiskFile'
      | 'FingerPrint'
      | 'FingerPrintTemplate'
      | 'Gateway'
      | 'GhCreator'
      | 'GhUser'
      | 'GhVideoCreator'
      | 'GiftCardPack'
      | 'Invoice'
      | 'Ip'
      | 'IpPool'
      | 'IppIp'
      | 'KakaoAccount'
      | 'KakaoFriend'
      | 'KolCreator'
      | 'MobileAccount'
      | 'None'
      | 'Orders'
      | 'PluginTeamPack'
      | 'Record'
      | 'RpaFlow'
      | 'RpaTask'
      | 'RpaTaskItem'
      | 'RpaVoucher'
      | 'Shop'
      | 'ShopSession'
      | 'Tag'
      | 'TeamDiskRoot'
      | 'TeamMobile'
      | 'TkCreator'
      | 'TkTeamPack'
      | 'TrafficPack'
      | 'TunnelVps'
      | 'Users'
      | 'View'
      | 'Voucher';
    /** resourceId */
    resourceId: number;
    // path
    /** tagId */
    tagId: number;
  },
  options?: { [key: string]: any },
) {
  const { tagId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/tag/${param0}/resource`, {
    method: 'DELETE',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 查询标签列表 GET /api/tags */
export async function tagsGet(
  params: {
    // query
    /** resourceType */
    resourceType:
      | 'AK'
      | 'Activity'
      | 'Audit'
      | 'BlockElements'
      | 'Cloud'
      | 'CrsOrder'
      | 'CrsProduct'
      | 'DiskFile'
      | 'FingerPrint'
      | 'FingerPrintTemplate'
      | 'Gateway'
      | 'GhCreator'
      | 'GhUser'
      | 'GhVideoCreator'
      | 'GiftCardPack'
      | 'Invoice'
      | 'Ip'
      | 'IpPool'
      | 'IppIp'
      | 'KakaoAccount'
      | 'KakaoFriend'
      | 'KolCreator'
      | 'MobileAccount'
      | 'None'
      | 'Orders'
      | 'PluginTeamPack'
      | 'Record'
      | 'RpaFlow'
      | 'RpaTask'
      | 'RpaTaskItem'
      | 'RpaVoucher'
      | 'Shop'
      | 'ShopSession'
      | 'Tag'
      | 'TeamDiskRoot'
      | 'TeamMobile'
      | 'TkCreator'
      | 'TkTeamPack'
      | 'TrafficPack'
      | 'TunnelVps'
      | 'Users'
      | 'View'
      | 'Voucher';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListTagVo>('/api/tags', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 批量删除标签 DELETE /api/tags */
export async function tagsDelete(
  params: {
    // query
    /** 标签ID，用逗号连接 */
    tagIds: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/tags', {
    method: 'DELETE',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
